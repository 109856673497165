import { globalHistory } from "@reach/router";
import clsx from "clsx";
import { graphql, navigate, useStaticQuery } from "gatsby";
import * as React from "react";
import {} from "react-feather";
import { useRecoilValue } from "recoil";
import { LanguageState } from "../../status/LanguageState";
import { loginHandler, logoutHandler } from "../../utility/LoginHandler";
import { MyLink } from "../elements/Link";
import MainLogo from "../elements/logos/MainLogo";
// import Logo from "../../assets/images/common__header__logo.svg"
import {
	urlFcFooterMenuList,
	urlFooterMenuList1,
	urlFooterMenuList3,
} from "../functional/urls";

export default function Footer() {
	const q: {
		cats: Nodes<WpCategory>;
		menu: WpMenu;
	} = useStaticQuery(graphql`
        query {
            cats: allWpCategory {
				nodes {
					...CategoryFragment
				}
			}
			menu: wpMenu( slug: {eq: "index_category"} ) {
				...MenuFragment
			}
        }
    `);
	const sortArray = React.useMemo(() => {
		const res: Edges<WpCategory> = { edges: [] };
		q.menu?.menuItems.nodes.find((item2) => {
			q.cats?.nodes.map((item) => {
				if (item2.label === item.name) {
					res.edges.push({ node: item });
				}
			});
		});
		return res;
	}, []);
	console.log("cat=>", sortArray);
	const userLogin = true; //useRecoilValue(userLoginState)
	const language = useRecoilValue(LanguageState);
	const { location } = globalHistory;
	const footerMenuList = userLogin
		? urlFcFooterMenuList()
		: urlFooterMenuList1();
	const sectionClass = "";
	const listClass =
		"tb:flex-col flex flex-wrap items-start justify-start text-xs gap-y-2 gap-x-4 text-sm";
	const cellClass = "block tb:inline-block";
	const titleClass = "text-[22px] mb-[0.5em]";
	console.log("hoge=>2", q.cats);
	return (
		<footer className="bg-main text-white px-6 py-10 tb:py-16">
			<div className="mx-auto max-w-screen-pc">
				<div className="flex-col tb:flex-row flex items-start justify-between gap-10">
					<section className={sectionClass}>
						<h5 className={titleClass}>MENU</h5>
						<ul className={clsx(listClass)}>
							{footerMenuList.map(({ label, to }, i) => {
								const isClick =
									to === "login" || to === "logout" || label === "カート";
								const dom = [];
								if (label === "カート") {
									dom.push(
										<button
											onClick={() => navigate(`${to}${location.href}`)}
											className="flex items-center"
											type="button"
										>
											{label}
										</button>,
									);
								}
								if (to === "login") {
									dom.push(
										<button
											onClick={() => loginHandler(true)}
											className="flex items-center"
											type="button"
										>
											{label}
										</button>,
									);
								}
								if (to === "logout") {
									dom.push(
										<button
											onClick={() => logoutHandler({ cb: () => {} })}
											className=""
											type="button"
										>
											{label}
										</button>,
									);
								}
								return (
									<li key={i} className={cellClass}>
										{!isClick && <MyLink to={to}>{label}</MyLink>}
										{dom}
									</li>
								);
							})}
						</ul>
					</section>
					<section className={sectionClass}>
						<h5 className={titleClass}>CATEGORY</h5>
						<ul className={clsx(listClass)}>
							<li className={cellClass}>
								<MyLink to={"/category/all/"}>ALL</MyLink>
							</li>
							{sortArray.edges.map(({ node: { slug, name } }, i) => {
								const to = `/category/${slug}/`;
								return (
									<li key={i} className={cellClass}>
										<MyLink to={to}>{name}</MyLink>
									</li>
								);
							})}
						</ul>
					</section>
					<section className={sectionClass}>
						<h5 className={titleClass}>OTHER</h5>
						<ul className={clsx(listClass)}>
							{urlFooterMenuList3().map(({ label, to }, i) => {
								return (
									<li key={i} className={cellClass}>
										<MyLink to={to}>{label}</MyLink>
									</li>
								);
							})}
						</ul>
					</section>
				</div>
				<div className="flex-col tb:flex-row flex items-center justify-start gap-4 mt-10">
					<div className="block tb:w-[53px] w-[108px]">
						<MainLogo />
					</div>
					<small className="block leading-none text-center">
						© {new Date().getFullYear()} {`${process.env.GATSBY_COPYRIGHT}`}
					</small>
				</div>
			</div>
		</footer>
	);
}
