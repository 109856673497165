import { globalHistory } from "@reach/router";
import clsx from "clsx";
import { type Variants, motion } from "framer-motion";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { useRecoilState } from "recoil";
import { ModalMenuState } from "../../status/ModalMenuState";
import { loginHandler, logoutHandler } from "../../utility/LoginHandler";
import { MyLink } from "../elements/Link";
import CartIcon from "../elements/icons/CartIcon";
import CloseIcon from "../elements/icons/CloseIcon";
import EnterIcon from "../elements/icons/EnterIcon";
import Instagram from "../elements/icons/Instagram";
import MenuIcon from "../elements/icons/MenuIcon";
import Twitter from "../elements/icons/Twiiter";
import {
	urlFcFooterMenuList,
	urlFcMenuList,
	urlFooterMenuList1,
	urlFooterMenuList3,
	urlMenuList,
	urlSns,
} from "../functional/urls";
import Logos from "../parts/Logos";

const snsList = [
	{
		to: urlSns.twitter,
		Icon: Twitter,
	},
	{
		to: urlSns.instagram,
		Icon: Instagram,
	},
];

const ContainerAnimation: Variants = {
	open: {
		x: "0%",
		opacity: 1,
		transition: {
			duration: 0.3,
			ease: "anticipate",
		},
	},
	init: {
		x: "100%",
		opacity: 0,
	},
	close: {
		x: "100%",
		opacity: 0,
	},
};

const NaviItemsAnimation = (i: number) => {
	const res: Variants = {
		open: {
			x: "0%",
			opacity: 1,
			transition: {
				duration: 0.3,
				delay: 0.04 * i,
				ease: "anticipate",
			},
		},
		init: {
			x: "100%",
			opacity: 0,
		},
		close: {
			x: "100%",
			opacity: 0,
		},
	};
	return res;
};

export default function ModalMenu() {
	const q: {
		cats: WpMenu;
	} = useStaticQuery(graphql`
        query {
            cats: wpMenu( slug: {eq: "menu_category"} ) {
				...MenuFragment
			}
        }
    `);
	const userLogin = true; //useRecoilValue(userLoginState)
	// const language = useRecoilValue(LanguageState)
	const { location } = globalHistory;

	// const { pathname } = globalHistory.location
	const [isModalMenu, setIsModalMenu] = useRecoilState(ModalMenuState);
	const modalHandler = () => {
		setIsModalMenu(!isModalMenu);
	};
	const menuList = userLogin ? urlFcMenuList : urlMenuList;
	const footerMenuList = userLogin
		? urlFcFooterMenuList()
		: urlFooterMenuList1();
	const isMember = userLogin;

	const onClickHandler = () => {
		if (isModalMenu) {
			setIsModalMenu(false);
		}
	};
	return (
		<>
			<motion.button
				whileHover={{ scale: 1.1 }}
				whileTap={{ scale: 0.9 }}
				onClick={modalHandler}
				className={clsx(
					"mix-blend-difference w-8 h-8 inline-block fixed top-4 right-5 z-[5000]",
					isMember ? "fcnav:hidden" : "nav:hidden",
				)}
			>
				{!isModalMenu ? <MenuIcon /> : <CloseIcon />}
			</motion.button>
			<motion.div
				onClick={(e) => e.stopPropagation()}
				variants={ContainerAnimation}
				initial="init"
				animate={isModalMenu ? "open" : "close"}
				exit="init"
				className={clsx(
					"fixed top-0 left-0 bg-white h-full w-full z-[4000]",
					// isModalOpen ? "block" : "hidden",
				)}
			>
				<div className="w-full h-full overflow-y-scroll pb-16 overflow-hidden">
					<nav className="">
						<ul
							className={clsx(
								"text-left text-[24px] font-extrabold",
								"[&>li]:py-3 [&>li]:px-6 [&>li]:border-brdr_gray_01 [&>li]:border-b-[1px] [&_a]:inline-block [&_a]:align-middle",
							)}
						>
							<li className="h-[64px]">
								<Logos isModal />
							</li>
							{q.cats.menuItems.nodes.map(({ label, path }, i) => {
								return (
									<li key={`${i}_cats`}>
										<MyLink to={path}>{label}</MyLink>
									</li>
								);
							})}
							{menuList.map(({ label, to }, i) => {
								const isClick = to === "login" || to === "logout";
								return (
									<motion.li
										key={i}
										variants={NaviItemsAnimation(i)}
										initial="init"
										animate={isModalMenu ? "open" : "close"}
										exit="init"
									>
										{!isClick && label !== "CART" && (
											<MyLink to={to} className="">
												{label}
												{label === "CART" && (
													<span className="-mb-[2px] ml-1 inline-block [&_path]:fill-[#000]">
														<CartIcon width={24} height={24} />
													</span>
												)}
											</MyLink>
										)}
										{label === "CART" && (
											<button
												type="button"
												onClick={() => {
													// onClickHandler();
													navigate(`${to}${location.href}`);
												}}
												className="flex items-center"
											>
												{label}
												<span className="ml-1 inline-block [&_path]:fill-[#000]">
													<CartIcon width={24} height={24} />
												</span>
											</button>
										)}
										{to === "login" && label !== "CART" && (
											<button
												type="button"
												onClick={() => {
													onClickHandler();
													loginHandler(true);
												}}
												className="flex items-center"
											>
												{label}
												<span className="-mb-[2px] ml-1 inline-block [&_path]:fill-[#000]">
													<EnterIcon width={24} height={24} />
												</span>
											</button>
										)}
										{to === "logout" && label !== "CART" && (
											<button
												onClick={() => {
													onClickHandler();
													logoutHandler({ cb: () => {} });
												}}
												className=""
												type="button"
											>
												{label}
											</button>
										)}
									</motion.li>
								);
							})}
						</ul>
						<ul className="text-left text-[16px] font-bold my-8">
							{urlFooterMenuList3().map(({ label, to }, i) => {
								return (
									<li key={i} className="px-6 my-2">
										<MyLink to={to} className="inline-block">
											{label}
										</MyLink>
									</li>
								);
							})}
						</ul>
						<ul className="flex items-center justify-start px-6 -mx-3">
							{snsList.map(({ to, Icon }, i) => {
								return (
									<li key={i} className="mx-3">
										<MyLink
											to={to}
											className="inline-flex items-center justify-center bg-main rounded-full w-10 h-10"
											onClick={onClickHandler}
										>
											<Icon />
										</MyLink>
									</li>
								);
							})}
						</ul>
					</nav>
				</div>
				<div className="absolute bottom-2 left-0 w-full text-center">
					<small className="text-xs text-txt_gray_02">
						© {new Date().getFullYear()} {`${process.env.GATSBY_COPYRIGHT}`}
					</small>
				</div>
			</motion.div>
		</>
	);
}
